import { Container, Typography, Grid, Card, CardContent, Button } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { useState } from 'react';
import Lcd from "../../../Assests/Portfolio/projectDetails/lcd.png"
import FigmaFrame from "../../../Assests/Projects/figmaFrame.png"
import TestImg from "../../../Assests/Projects/testImgA.png"
import Maditask from "../../../Assests/Portfolio/projectDetails/meditaskpro.png"

import { Link } from 'react-router-dom';
import { animateScroll as scroll } from "react-scroll";

const NewProjectOverview = ({ project }) => {



    return (
        <Container maxWidth="xl" sx={{ height: "100%", marginTop: "3rem" }} >
            <Grid container spacing={5}>
                <Grid item xs={12} md={6} lg={7}>
                    <Card sx={{ ...themeStyles.mediaCard, paddingTop: '0rem' }} >
                        <Carousel
                            showThumbs={false}
                            showStatus={false}
                            infiniteLoop={true}
                            autoPlay={true}
                            interval={2000}
                            showArrows={false}
                            showIndicators={false}
                        >

                            {project.images?.map((image, index) => (
                                <div key={index} style={{ width: '100%', height: '100%', paddingTop: "4rem", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <img src={image} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} alt={`IMAGE ${index}`} />
                                </div>
                            ))}
                        </Carousel>
                    </Card>
                </Grid>
                <Grid item xs={12} md={5} lg={5} sx={{ textAlign: 'left', marginLeft: { lg: "0rem", md: "3rem", sm: "0rem", xs: "0rem" } }}>
                    <Typography sx={themeStyles.summaryHeading}>
                        PROJECT SUMMARY
                    </Typography >
                    <Typography sx={{ ...themeStyles.summaryHeading, ...themeStyles.descriptionText }}>
                        {project?.description}
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    )
};

export default NewProjectOverview;

const themeStyles = {
    mediaCard: {
        boxShadow: 'none',
        borderRadius: '0px',
        backgroundBlendMode: 'darken',
        background: 'transparent',
        width: '38.25rem',
        // width: "100%",
        height: '28.75rem',
        flexShrink: 0,
        backgroundImage: `url(${FigmaFrame})`,
        backgroundSize: 'cover',
        '@media (max-width: 1600px) and (min-width: 1201px)': {
            width: '90%', height: '100%'
        },
        '@media (max-width: 1200px) and (min-width: 901px)': {
            width: '100%', height: 'auto'
        },
        '@media (max-width: 900px) and (min-width: 801px)': {
            width: '100%', height: 'auto'
        },
        '@media (max-width: 800px) and (min-width: 601px)': {
            width: '100%', height: 'auto'
        },
        '@media (max-width: 600px)': {
            width: '100%', height: 'auto'
        }
    },
    descriptionText: {
        marginRight: '1rem',
        fontSize: { lg: "1rem", sm: "0.9rem", xs: "0.75rem" }, fontWeight: 500, fontFamily: "poppins", width: { lg: "auto", md: "100%", sm: "auto", xs: "100%" },
    },
    summaryHeading: {
        color: "#1B2231",
        fontFamily: "Montserrat",
        marginBottom: "2rem",
        fontSize: { lg: "1.5rem", md: "1.2rem", sm: "1.1rem", xs: "1rem" },
        fontStyle: "normal",
        fontWeight: 600,
    },
}

