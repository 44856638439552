import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import { Container } from '@mui/material';
export default function ControlledAccordions({ category }) {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


  const accordionSummaryStyle = {
    backgroundColor: '#F6F6F6',
  };
  const accordionStyle = {
    border: "none", boxShadow: "none", padding: { sm: "0.5rem 0rem 0.5rem 0rem", xs: "0rem 0rem 0rem 0rem" }, backgroundColor: '#F6F6F6', borderRadius: 0
  }
  const accordianSummaryTypo = {
    width: '90%', flexShrink: 0, color: '#1B2231', fontSize: { lg: '1.12rem', sm: '1rem', xs: '0.75rem' }, fontFamily: "Inter", fontWeight: 500
  }
  const accordianDetailsTypo = {
    color: '#1B2231', fontSize: { lg: "1rem", sm: "1rem", xs: "0.75rem" }, fontFamily: "Inter"
  }

  return (
    <Container style={{ border: 'none', fontFamily: "Montserrat", background: '#F6F6F6', textAlign: 'left', fontWeight: 400 }}>
      <Typography component="h5" sx={{ color: '#1B2231', fontSize: { lg: '2.5rem', md: '2rem', sm: '1.5rem', xs: '1.2rem' }, fontWeight: 600, textAlign: 'center', padding: '1.5rem 0rem 1.5rem 0rem' }}>Frequently Asked Questions {"(FAQ's)"}</Typography>
      <Typography sx={{ color: '#545454', fontFamily: "Montserrat", fontSize: { lg: '1rem', sm: '0.8rem', xs: '0.75rem' }, fontWeight: 400, textAlign: 'center', padding: '0.5rem 0rem 3rem 0rem', marginTop: '1%' }}>See the answer to some of our most commonly asked questions below.</Typography>


      {data[category] && Object.values(data[category]).map((faq, index) => (
        <>
          <Accordion key={index} expanded={expanded === `panel${index}`} onChange={handleChange(`panel${index}`)} sx={{ ...accordionStyle, borderBottom: index === Object.keys(data[category]).length - 1 ? '1px solid #D8D8D8' : 'none' }}>
            <AccordionSummary
              expandIcon={expanded === `panel${index}` ? <RemoveIcon style={{ color: '#1B2231' }} /> : <AddIcon style={{ color: '#1B2231' }} />}
              aria-controls={`panel${index}bh-content`}
              id={`panel${index}bh-header`}
              style={accordionSummaryStyle}
            >
              <Typography sx={accordianSummaryTypo}>{faq.heading}</Typography>
            </AccordionSummary>
            <AccordionDetails style={{ backgroundColor: '#F6F6F6' }}>
              <Typography sx={accordianDetailsTypo}>{faq.description}</Typography>
            </AccordionDetails>
          </Accordion>


        </>
      ))}


    </Container>
  );
}

const data = {
  "Front End": {
    0: {
      heading: "What is Material-UI and how is it used in front-end projects?",
      description: "Material-UI is a popular React UI framework that implements Google's Material Design. It provides ready-to-use components and styles for building modern, responsive user interfaces. In front-end projects, Material-UI simplifies the development process by offering pre-designed components like buttons, inputs, and navigation elements, along with a consistent design language."
    },
    1: {
      heading: "How does Material-UI enhance the user experience?",
      description: "Material-UI enhances the user experience by providing visually appealing and interactive components that adhere to Material Design principles. These components offer smooth animations, responsive behavior, and accessibility features, ensuring a seamless and engaging user interface."
    },
    2: {
      heading: "Can Material-UI be customized to match brand styles?",
      description: "Yes, Material-UI offers extensive customization options to match brand styles. You can easily modify the theme colors, typography, spacing, and other design attributes to align with your brand identity. Additionally, Material-UI provides styling solutions such as CSS-in-JS, theming, and global styles to achieve the desired look and feel."
    },
    3: {
      heading: "What are some other popular CSS frameworks/libraries used in front-end development?",
      description: "Besides Material-UI, there are several other popular CSS frameworks and libraries used in front-end development, such as Bootstrap, Tailwind CSS, Semantic UI, and Foundation. These frameworks provide pre-designed components, responsive layouts, and utility classes to streamline the development process and maintain consistency across projects."
    },
    4: {
      heading: "How do CSS frameworks contribute to responsive web design?",
      description: "CSS frameworks like Material-UI and Bootstrap offer responsive grid systems and utility classes that enable developers to create layouts that adapt to different screen sizes and devices. By leveraging these features, front-end developers can ensure that their web applications are accessible and user-friendly on desktops, tablets, and mobile devices."
    },
    5: {
      heading: "What are the advantages of using Material-UI over other CSS frameworks?",
      description: "Material-UI offers several advantages over other CSS frameworks, including comprehensive React integration, robust documentation, active community support, and seamless compatibility with Material Design guidelines. Additionally, Material-UI's component-based architecture promotes code reusability, modularity, and maintainability, making it a preferred choice for front-end development."
    }
  },
  PCB: {
    0: {
      heading: "What ensures Octathorn's PCBs are of top quality?",
      description: "We employ experienced professionals, rigorous testing, and cutting-edge technologies."
    },
    1: {
      heading: "How does Octathorn provide round-the-clock support for PCB projects?",
      description: "Our support team is available 24x7 to address inquiries and provide technical assistance."
    },
    2: {
      heading: "What's Octathorn's approach to delivering successful PCB projects?",
      description: "We prioritize strategic planning, efficient execution, and continuous evaluation."
    },
    3: {
      heading: "What sets Octathorn's support apart in the PCB industry?",
      description: "Discover how we deliver unparalleled assistance and top-tier customer service."
    },
    4: {
      heading: "What innovative techniques does Octathorn use in PCB development?",
      description: "We employ cutting-edge RDI techniques, market analysis, and collaboration."
    },
    5: {
      heading: "Why trust Octathorn's experienced professionals for PCB projects?",
      description: "Our team brings deep expertise and a commitment to delivering high-quality, reliable PCBs."
    }
  },
  "Embedded": {
    0: {
      heading: "What ensures Octathorn's Embedded Systems are of top quality?",
      description: "We deliver unparalleled quality through experienced professionals and cutting-edge technologies."
    },
    1: {
      heading: "How does Octathorn provide round-the-clock support for Embedded Systems?",
      description: "Our 24x7 live support ensures immediate assistance and uninterrupted service."
    },
    2: {
      heading: "What's Octathorn's approach to delivering result-oriented Embedded Systems projects?",
      description: "We prioritize strategic planning, efficient execution, and continuous evaluation."
    },
    3: {
      heading: "What sets Octathorn's support apart in the Embedded Systems industry?",
      description: "Our support team is renowned for delivering exceptional assistance and top-tier customer service."
    },
    4: {
      heading: "What innovative RDI techniques does Octathorn employ in Embedded Systems development?",
      description: "We utilize cutting-edge RDI techniques and foster a culture of innovation for groundbreaking solutions."
    },
    5: {
      heading: "Why trust Octathorn's experienced professionals for Embedded Systems projects?",
      description: "Our team brings deep expertise and a commitment to delivering solutions that reflect the pinnacle of experience."
    }
  },
  "IoT": {
    0: {
      heading: "What ensures Octathorn's IoT solutions are of top quality?",
      description: "We deliver quality through experienced professionals, rigorous testing, and cutting-edge technologies."
    },
    1: {
      heading: "How does Octathorn provide round-the-clock support for IoT solutions?",
      description: "Our 24x7 live support ensures immediate assistance and uninterrupted service."
    },
    2: {
      heading: "What's Octathorn's approach to delivering result-oriented IoT projects?",
      description: "We prioritize strategic planning, efficient execution, and continuous evaluation."
    },
    3: {
      heading: "What distinguishes Octathorn's support in the IoT industry?",
      description: "Our support team is renowned for delivering exceptional assistance and top-tier customer service."
    },
    4: {
      heading: "What innovative RDI techniques does Octathorn employ in IoT development?",
      description: "We utilize cutting-edge RDI techniques and foster a culture of innovation for groundbreaking solutions."
    },
    5: {
      heading: "Why trust Octathorn's experienced professionals for IoT projects?",
      description: "Our team brings deep expertise and a commitment to delivering solutions that reflect the pinnacle of experience."
    }
  },
  "Back End, Database": {
    0: {
      heading: "How does Octathorn ensure top quality in Back End and Database development?",
      description: "We deliver quality through experienced professionals, rigorous testing, and cutting-edge technologies."
    },
    1: {
      heading: "What support does Octathorn offer for Back End and Database projects?",
      description: "Our 24x7 live support ensures immediate assistance and uninterrupted service."
    },
    2: {
      heading: "What's Octathorn's approach to delivering result-oriented Back End and Database projects?",
      description: "We prioritize strategic planning, efficient execution, and continuous evaluation."
    },
    3: {
      heading: "How does Octathorn stay at the forefront of Back End and Database innovation?",
      description: "We utilize cutting-edge technologies and continuous learning to drive innovation."
    },
    4: {
      heading: "Why trust Octathorn's experienced professionals for Back End and Database projects?",
      description: "Our team brings deep expertise and a commitment to delivering solutions that exceed expectations."
    },
    5: {
      heading: "What are the key benefits of choosing Octathorn for Back End and Database development?",
      description: "We offer scalable solutions, efficient project management, and seamless integration to meet your business needs."
    }
  },
  Mobile: {
    0: {
      heading: "How does Octathorn ensure top quality in mobile app development?",
      description: "We deliver unparalleled quality through experienced professionals, rigorous testing protocols, and cutting-edge technologies."
    },
    1: {
      heading: "What support does Octathorn offer for mobile app projects?",
      description: "Our 24x7 live support ensures immediate assistance and uninterrupted service for your mobile app needs."
    },
    2: {
      heading: "What's Octathorn's approach to delivering result-oriented mobile app projects?",
      description: "We prioritize strategic planning, efficient execution, and continuous evaluation to exceed client expectations in mobile app development."
    },
    3: {
      heading: "How does Octathorn's support team ensure customer satisfaction in mobile app development?",
      description: "Our dedicated support team is committed to providing exceptional assistance, ensuring your satisfaction throughout the mobile app development process."
    },
    4: {
      heading: "How does Octathorn utilize cutting-edge techniques in mobile app development, including Flutter?",
      description: "We employ cutting-edge technologies like Flutter, along with Research and Development and Innovation (RDI) techniques, fostering a culture of innovation to create groundbreaking mobile solutions."
    },
    5: {
      heading: "Why trust Octathorn's experienced professionals for mobile app development?",
      description: "Our team of highly experienced professionals brings a deep understanding of industry trends and best practices, ensuring the delivery of top-notch mobile solutions."
    }
  }
}